/* Estilos para o Navbar no mesmo estilo da tela de login */

.styled-navbar {
  background-image: url(../../../public/images/fundobarra4.png);
  background-repeat: no-repeat; /* Faz a imagem se repetir */
  background-size: cover; /* Ajusta o tamanho da imagem, pode ser alterado conforme necessidade */
  height: 65px !important; /* Altura da barra de navegação */
  width: 100%; /* Garante que a barra de navegação use toda a largura disponível */
  }
  
  .navbar-brand {
    color: #ff2200; /* Cor do texto da marca */
    font-weight: bold; /* Negrito para destaque */
  }
  
  .navbar-link {
    color: white;
    font-size: 1.1em;
    display: flex;
    align-items: center;
    transition: color 0.3s ease-in-out;
  }
  
  .navbar-link:hover {
    color: #ff2200;
  }
  
  .navbar-icon {
    margin-right: 5px;
  }
  
  .navbar-brand:hover,
  .navbar-link:hover {
    color: #ffe6d8; /* Tom claro de laranja no hover */
  }

.logonovogame {
  height: 55px !important; /* Ajusta a altura */
  width: auto !important; /* Ajusta a largura proporcionalmente */
  
}


  /* Custom content (título com logo) */
  .custom-content {
    text-align: center;
    flex-grow: 1;
    margin-top:10px;
  }
  
  .custom-content h1 {
    font-size: 1.5em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center; 
    gap: 10px; /* Espaço entre os logos e o texto */
    display: inline-block;
  }
  
  
  .navbar-logo {
    width: 40px;
    height: 40px;
  }
  
  /* Ajuste responsivo */
  @media (max-width: 768px) {
    .navbar-brand {
      font-size: 1.3em;
    }
  
    .navbar-link {
      font-size: 1em;
    }
  
    .custom-content h1 {
      font-size: 1.2em;
      margin: 0;
    }
  
    .navbar-logo {
      width: 30px;
      height: 30px;
    }
  
  }
  
  .icone {
    position: absolute;
    right: 5%;
    display: inline;
    text-align: right;
  }

  .nameuser {
    font-size: 16px; /* Tamanho da fonte */
    font-weight: bold; /* Negrito */
    margin: 0; /* Remover margens para evitar qualquer espaçamento extra */
    pointer-events: none;
    text-decoration: none;
  }