/* Estilo para o GIF de confete */
#confetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('../../../public/images/confetti.gif');
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1000;
  pointer-events: none;
}

/* Estilo da página do vencedor */
#vencedor .container-fluid {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-image: url('../../../public/images/fundovencedor2.png'); /* Caminho do fundo */
  background-size: cover;
  background-repeat: no-repeat;
}

/* Estilos da imagem do vencedor */
.vencedor-image {
  margin-top: -200px; /* Ajuste este valor conforme necessário */
  height: 300px;
  width: auto;
  z-index: 2;
}

/* Estilos de texto */
#vencedor h1 {
  font-size: 70px;
  color: #0046e6;
  margin: 0;
  padding: 10px 0;
}

#vencedor p {
  font-size: 25px;
  color: #0046e6;
  margin-top: 10px;
  margin-bottom: 30px;
}

/* Botões */
.button-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
}

.botao {
  display: inline-block;
  background: orange;
  padding: 15px 25px;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  font-size: 20px;
  width: 180px;
  text-align: center;
  transition: background 0.3s, box-shadow 0.3s;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
}

.botao:hover {
  background: orangered;
  cursor: pointer;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
}


