/* Estilo para a barra de navegação */
.navbar-sobre {
    background-image: url(../../../public/images/fundobarra4.png);
    background-repeat: no-repeat; 
    background-size: cover; 
    height: 65px; /* Altura da barra de navegação */
    width: 100%; /* Garante que a barra de navegação use toda a largura disponível */
}

/* Estilo para a marca */
.navbar-brand {
    color: rgb(255, 34, 0); /* Cor do texto da marca */
    font-weight: bold; /* Negrito para destaque */
}

.navbar-brand:hover{
    color: rgb(255, 255, 255);
}

.icone img {
    width: 50px; /* Define a largura do ícone */
    height: auto; /* Mantém a proporção */
    margin-top: 0%;
    margin-right: 30px;
}

/* Container da navbar para usar flexbox */
.d-flex {
    display: flex;
    justify-content: space-between; /* Espaça os itens entre eles */
    align-items: center; /* Alinha verticalmente no centro */
}

#sobre {
    text-align: center;
}

#sobre h1 {
    color: #ff8800; /* Laranja vibrante */
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

#sobre h1 img {
    width: 50px;
    animation: bounce 1.5s infinite;
}

#navSobre {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#sobre .ceo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    margin-top: 20px;
}

#sobre .container {
    display: flex;
    justify-content: space-around;
    gap: 20px;
}

#sobre .col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#sobre .box {
    background-color: #ff6f00; /* Laranja escuro */
    color: white;
    padding: 15px;
    border-radius: 12px;
    width: 220px;
    text-align: center;
    margin-bottom: 20px;
    transition: transform 0.2s, box-shadow 0.3s;
    cursor: pointer;
}

#sobre .box:hover {
    transform: scale(1.05);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

#sobre .sub-boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
}

#sobre .analista {
    background-color: #ffa726; /* Laranja médio */
    color: white;
    margin-top: 10px;
    padding: 12px;
    border-radius: 8px;
    width: 200px;
    text-align: center;
    transition: background-color 0.3s, transform 0.2s;
}

#sobre .analista:hover {
    background-color: #ffb74d;
    transform: scale(1.05);
}

#sobre .ceo {
    font-size: 18px;
    margin-bottom: 10px;
    background-color: #ff3d00; /* Laranja vibrante */
}

#sobre .front, #sobre .back, #sobre .bd {
    font-size: 18px;
    background-color: #ff6f00;
    color: white;
    border-radius: 8px;
    padding: 8px;
    width: 180px;
}

#sobre p {
    margin-bottom: 5px;
}

#sobre span {
    font-size: 14px;
    font-weight: 300;
}

a {
    text-decoration: none;
  }
  
a .box.analista {
    text-decoration: none;
  }
  