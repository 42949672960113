#root { 
  background-size: cover!important;
  background-repeat: no-repeat !important; 
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  background-image: url('../public/images/natureza.png');
  font-weight: bold;
}

.fixed-buttons {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  gap: 5px;
  z-index: 1000; /* Garante que os botões fiquem acima de outros elementos */
}




body .container h2{
  width: 100%;
  font-size: 50px;
  margin: 0;
  font-family: "Matemasie", sans-serif;
  font-weight: bold;
  color: #0a300a;
  background-color: transparent;
}

body h1{
  width: 100%;
  font-size: 50px;
  margin: 0;
  font-family: "Matemasie", sans-serif;
  font-weight: bold;
  color: #ffffff;
  background-color: transparent;
  margin-bottom: 10px;
}

.botaoRodape {
  background-color: #41616E; /* Cor de fundo */
  color: white; /* Cor do texto */
  border: none; /* Remove bordas padrão */
  padding: 5px 10px; /* Espaçamento interno */
  border-radius: 5px; /* Bordas arredondadas */
  cursor: pointer; /* Muda o cursor ao passar o mouse */
  transition: background-color 0.3s ease; /* Transição suave para a cor de fundo */
}

.botaoRodape:hover {
  background-color: #C9E8FC; /* Cor ao passar o mouse */
  color: #41616E; /* Muda a cor do texto ao passar o mouse */
}

/* Estilos para Toast */
.p-toast {
  font-family: 'Roboto', sans-serif;
}

.p-toast-top-right {
  top: 80px; /* Distância para não sobrepor o Navbar */
}

.p-toast-message {
  background-color: #ff8800 !important; /* Fundo laranja */
  color: #fff !important;
  border-radius: 12px !important;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
  padding: 15px;
}

.p-toast-icon {
  color: #fff !important;
}

.p-toast-summary {
  font-weight: bold;
  font-size: 1.2em;
}

.p-toast-detail {
  font-size: 0.9em;
}

/* Animação para exibir e ocultar o Toast */
.p-toast-message-enter-active {
  animation: toastIn 0.3s forwards;
}

.p-toast-message-leave-active {
  animation: toastOut 0.3s forwards;
}

/* Estilos para ConfirmDialog */
.p-confirm-dialog {
  font-family: 'Roboto', sans-serif;
  background-color: #41616E !important; /* Fundo com cor de jogo */
  border-radius: 12px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
  color: #ff8800;
  max-width: 500px;
}

.p-confirm-dialog .p-confirm-dialog-message {
  color: #ff8800; /* Cor do texto */
  font-size: 1.2em;
  font-weight: bold;
  padding: 20px;
}

.p-confirm-dialog .p-dialog-header {
  background-color: #ff8800 !important; /* Cor de fundo do header */
  color: #ffffff;
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 15px;
}

.p-confirm-dialog .p-dialog-footer .p-button {
  font-weight: bold;
  color: #41616E; /* Cor do botão */
  background-color: #C9E8FC; /* Fundo do botão */
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.p-confirm-dialog .p-dialog-footer .p-button:hover {
  background-color: #ff8800; /* Cor ao passar o mouse */
  color: #ffffff;
}

/* Animação de entrada e saída */
.p-confirm-dialog-enter-active {
  animation: dialogIn 0.4s forwards;
}

.p-confirm-dialog-leave-active {
  animation: dialogOut 0.4s forwards;
}

@keyframes dialogIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes dialogOut {
  from {
    opacity: 1;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(0.9);
  }
}


@keyframes toastIn {
  from {
      opacity: 0;
      transform: translateX(100%);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes toastOut {
  from {
      opacity: 1;
      transform: translateX(0);
  }
  to {
      opacity: 0;
      transform: translateX(100%);
  }
}

.hover:hover{
  cursor: pointer;
  transform: scale(1.1)
}