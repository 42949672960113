/* Estilo para a barra de navegação */
.navbar-regras {
    background-image: url(../../../public/images/fundobarra4.png);
    background-repeat: no-repeat; /* Faz a imagem se repetir */
    background-size: cover; /* Ajusta o tamanho da imagem, pode ser alterado conforme necessidade */
    height: 65px; /* Altura da barra de navegação */
    width: 100%; /* Garante que a barra de navegação use toda a largura disponível */
}

/* Estilo para a marca */
.navbar-brand {
    color: rgb(255, 34, 0); /* Cor do texto da marca */
    font-weight: bold; /* Negrito para destaque */
}

.navbar-brand:hover{
    color: rgb(255, 255, 255);
}
.icone img {
    width: 50px; /* Define a largura do ícone */
    height: auto; /* Mantém a proporção */
    margin-top: 0%;
    margin-right: 30px;
}

/* Container da navbar para usar flexbox */
.d-flex {
    display: flex;
    justify-content: space-between; /* Espaça os itens entre eles */
    align-items: center; /* Alinha verticalmente no centro */
}

#regras section {
    border: none;
    margin-bottom: 0px;
    padding: 0%;
}

#regras .divisor {
    width: 20%;
    height: 90%;
}

#regras .container-fluid {
    color: rgb(0, 0, 0);/*texto*/
    font-size: 24px;
    text-align: center;
    margin-top: 2%;
    overflow-y: auto;  /* Adiciona rolagem vertical se o conteúdo for maior que o container */
    max-height: 100vh;  /* Limita a altura ao tamanho da viewport */
    padding: 20px;
    box-sizing: border-box;
    height: 85vh;
    background-color: rgba(255, 255, 255, 0.49);
    border-radius: 50px;
}

img#cartas{
    width: 80%;
    height: auto;
}

