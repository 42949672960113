

  @keyframes pulse {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  #user .stat-title {
    font-size: 1.5em;
    color: #ff6f47;
    font-weight: bold;
    animation: pulse 2s infinite ease-in-out;
  }
  
  #user .stat-badge {
    font-size: 1.5em;
    color: #ff6f47;
    font-weight: bold;
  }
  
  
  #user .container-fluid {
    color: #ff6f47;
    text-align: center;
    font-weight: bold;
  }
  
  #user .card {
    background-color: #fff5e6;
    border: 2px solid #ff9a5c;
    border-radius: 15px;
    box-shadow: 0px 0px 20px 5px rgba(255, 175, 75, 0.3);
  }
  
  #user .profile-icon {
    width: 100px;
    margin-bottom: 20px;
  }
  
  #user .iconePerfil h2 {
    font-size: 2em;
    color: #ff6f47;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  #user .form-label {
    color: #ff6f47;
    font-weight: bold;
  }
  
  #user .form-control, #user .form-select {
    background-color: #fff0e1;
    border: 2px solid #ff9a5c;
    color: #ff6f47;
    border-radius: 8px;
  }
  
  #user  .form-control::placeholder {
    color: #ff9a5c;
  }

  #user  .form-select::placeholder {
    color: #ff9a5c;
  }
  
  #user .stat-badge {
    font-size: 1.5em;
    color: #ff6f47;
    font-weight: bold;
  }
  
  #user button, #user .btn-primary {
    background-color: #ff7f50;
    border: none;
    color: white;
    font-size: 1.2em;
    padding: 10px;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0 4px 6px rgba(255, 112, 75, 0.3);
    transition: background-color 0.3s ease-in-out;
  }
  
  #user button:hover, #user .btn-primary:hover {
    background-color: #ff6b3b;
  }
  
  #user .dropdown .p-dropdown {
    color: #ff6f47;
    background-color: #fff0e1;
  }
  
  #user .dropdown .p-dropdown-panel {
    border-color: #ff9a5c;
  }

  #user .stat-badge-input {
    width: 70px;
    text-align: center;
    font-size: 1.2rem;
    border: none;
    background: transparent;
    color: #333;
}

.password-container {
  position: relative;
}

.password-container .eye-icon {
  position: absolute;
  right: 10px; 
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer; 
  color: #ff6f47; 
}

  
  /* Ícones de vitórias, derrotas e saldo */
  #user .d-flex .text-warning {
    color: #ff6f47;
  }
  
  #user #divMoeda2 {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffecd2;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    font-size: 1.1em;
    color: #ff6f47;
  }
  