.websocket-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-image: url('../../../public/images/natureza.png'); /* Certifique-se de que o caminho da imagem está correto */
    background-size: cover;
    background-position: center;
    flex-direction: column;
    position: relative; /* Para criar uma sobreposição opaca */
  }
  .desist-button {
    margin-top: 20px; /* Espaçamento acima do botão */
    padding: 10px 20px; /* Espaçamento interno */
    font-size: 1.5rem; /* Tamanho da fonte */
    font-family: 'Roboto', sans-serif; /* Fonte */
    color: #fff; /* Cor do texto */
    background-color: #d9534f; /* Cor de fundo (vermelho) */
    border: none; /* Sem borda */
    border-radius: 5px; /* Bordas arredondadas */
    cursor: pointer; /* Cursor de mão ao passar o mouse */
    z-index: 2; /* Coloca o botão acima da sobreposição */
}

.desist-button:hover {
    background-color: #c9302c; /* Cor de fundo ao passar o mouse */
}

  
  .websocket-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo opaco preto com 50% de opacidade */
    z-index: 1; /* Coloca a sobreposição abaixo do conteúdo */
  }
  
  .loading-text, .received-message {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    color: #f48c06;
    z-index: 2; /* Coloca o texto acima da sobreposição */
  }
  
  .dots::after {
    content: '';
    animation: dots 1.5s steps(5, end) infinite;
  }
  
  @keyframes dots {
    0%, 20% {
      content: '';
    }
    40% {
      content: '.';
    }
    60% {
      content: '..';
    }
    80%, 100% {
      content: '...';
    }
  }
  
  .received-message {
    font-size: 2rem;
    font-family: 'Roboto', sans-serif;
    color: #06d6a0;
  }
  