.bem_vindo { 
    color: #FFF;
}

.mensagem-status {
    display: none; /* Oculta as mensagens */
}

#lagoa {
    position: absolute;
    margin-left: 12%;
    margin-right: 12%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: inline;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}


#lagoa .imgLagoa{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    width: 45vh;
    height: 45vh;
}

#lagoa .envelope 
{
    position: relative;
    width: 120vh;
    height: calc( 100vh - 49px );
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

#lagoa .envelope .pilha 
{ 
    display: block;
    width: 13vh;
    height: 16vh;
    position: absolute;
    transform-origin: 50% 50%; /* Ponto de origem ajustado para a borda esquerda no centro vertical */
}

#lagoa .envelope #pilha1 { transform: rotate(0deg) translateY(27vh); } 
#lagoa .envelope #pilha2 { transform: rotate(315deg) translateY(27vh); } 
#lagoa .envelope #pilha3 { transform: rotate(270deg) translateY(27vh); } 
#lagoa .envelope #pilha4 { transform: rotate(225deg) translateY(27vh); } 
#lagoa .envelope #pilha5 { transform: rotate(180deg) translateY(27vh); } 
#lagoa .envelope #pilha6 { transform: rotate(135deg) translateY(27vh); } 
#lagoa .envelope #pilha7 { transform: rotate(90deg) translateY(27vh); } 
#lagoa .envelope #pilha8 { transform: rotate(45deg) translateY(27vh); } 


/*
#lagoa .envelope #pilha1 { transform: rotate(-270deg) translateX( 25vh ); } 
#lagoa .envelope #pilha2 { transform: rotate(-315deg) translateX( 25vh ); } 
#lagoa .envelope #pilha3 { transform: rotate(0deg) translateX( 25vh );} 
#lagoa .envelope #pilha4 { transform: rotate(-45deg) translateX( 25vh );} 
#lagoa .envelope #pilha5 { transform: rotate(-90deg) translateX( 25vh );} 
#lagoa .envelope #pilha6 { transform: rotate(-135deg) translateX( 25vh ); } 
#lagoa .envelope #pilha7 { transform: rotate(-180deg) translateX( 25vh ); } 
#lagoa .envelope #pilha8 { transform: rotate(-225deg) translateX( 25vh ); } 
*/

#lagoa .pilha .carta img { width: 90%; height: auto;}
#lagoa .carta .cartaMensagem { display: none; }

/*
#lagoa .envelope {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#lagoa .pilha {
    position: absolute;
    border: 1px solid black;
    width: 10%;
    max-width: 9%;
    min-height: 20%;

}

#lagoa .pilha .carta { display: none; }
#lagoa .pilha .carta img { width: 100%; height: auto;}
#lagoa #pilha1{
    top: 70%;
    transform-origin: top center;
}

#lagoa #pilha2{
    top: 63%;
    left: 49% ;
    transform: rotate(-45deg);
    transform-origin: top center;
}

#lagoa #pilha3{
    top:50%;
    left: 52%;
    transform: rotate(-90deg);
    transform-origin: top center;
}

#lagoa #pilha4{
    top: 35%;
    left: 49%;
    transform: rotate(-135deg);
    transform-origin: top center;
}

#lagoa #pilha5{
    top: 30%;
    transform: rotate(-180deg);
    transform-origin: top center;
}

#lagoa #pilha6{
    top: 35%;
    right: 49%;
    transform: rotate(-225deg);
    transform-origin: top center;
}

#lagoa #pilha7{
    top: 50%;
    right: 52%;
    transform: rotate(-270deg);
    transform-origin: top center;
}


#lagoa #pilha8{
    top: 63%;
    right: 49% ;
    transform: rotate(-315deg);
    transform-origin: top center;
}
*/

.pilha .carta:nth-child(1) {
    position:relative;
    top:0px;
    left:0px;
}
.pilha .carta:nth-child(2) {
    position:absolute;
    top:10px;
}
.pilha .carta:nth-child(3) {
    position:absolute;
    top:20px;
}
.pilha .carta:nth-child(4) {
    position:absolute;
    top:30px;
}
.pilha .carta:nth-child(5) {
    position:absolute;
    top:40px;
}
.pilha .carta:nth-child(6) {
    position:absolute;
    top:50px;
}
.pilha .carta:nth-child(7) {
    position:absolute;
    top:60px;
}
.pilha .carta:nth-child(8) {
    position:absolute;
    top:70px;
}
.pilha .carta:nth-child(9) {
    position:absolute;
    top:80px;
}

.pilha .carta img {
    width:50%;
}

.deck {
    position: absolute;
    top: 5%;
    right: 10%;
    display: inline;
    text-align: right;
}

.deck img {
    width:13vh;
}

/** Encerrar jogada */
.deck .overlay{
    position: absolute;
    width: 100%;
    height: 100%;   
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deck .overlay span {
    display: block;
    border-radius: 50px;
    background-color: #C9E8FC;
    width: 75px;
    text-align: center;
    padding: 15px 0;
    font-size: 0.8em;
    cursor: pointer;
}
.deck .overlay span:hover{ 
    background-color: #fff;
}
.deck:hover img, #mao .carta img:hover {
    transform: scale(1.1);
}

#placar {
    position: absolute;
    bottom: 8%;
    right: 1%;
    display: inline;
    text-align: right;
}

#placar h6 {
    bottom: 5%;
    font-size: 30px;
    font-weight: bold;
    position: absolute;
}

#placar img{
    width: 40vh;
}

#mao {
    position: absolute;
    top: 0%;
    left: 2%;
    bottom:2%;
    margin:5px;
    width:80px;
    display: inline;
    text-align: center;
    font-family: Arial;
}

#mao{
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    box-sizing: border-box;
}

#mao .cartas { 
    background-color: transparent; 
    flex-grow: 1; 
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

#mao .carta { margin-top:3px; width: 100%; height: 20%; }
#mao .carta:nth-child(1) { margin-top: 0;}

#mao .carta img{
    max-height:100%;
    width: auto;
    height: auto;
}

.botoes {
    margin: 0;
    position: absolute;
    top: 56%;
    left: 50%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}

#sairJogo {
    margin: 0;
    position: absolute;
    top: 49%;
    left: 87%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
}

.botoes button{
    background-color: #C9E8FC;
    color: #41616E;
    border: 2px solid #41616E;
    font-size: larger;
    font-weight: bold;
    width: 20vh
}

.botaoCancelar{
    position: absolute;
    top: 5%;
    left: 10%;
    display: inline;
    text-align: right;
}

.botaoCancelar button{
    border: 2px solid;
    font-size:medium;
    font-weight: bold;
    width: 20vh
}



#maoAdversario {
    position: absolute;
    top: 0%;
    right: 1%;
    bottom:2%;
    margin-top:20px;
    width:80px;
    text-align: center;
    font-family: Arial;
}

.adversario img{
    width: 8vh;
    margin-bottom:5px;
}


.protanopia {
    filter: url('#protanopia-filter');
  }
  
  .deuteranopia {
    filter: url('#deuteranopia-filter');
  }
  
  .tritanopia {
    filter: url('#tritanopia-filter');
  }
  
  .img:hover{
    cursor: 'pointer';
    transform: scale(1.1)
  }


 
#mao .carta {
    position: relative;
    display: inline-block;
  }

#mao  .cartaMensagem {
    position: absolute;
    top: 50%;
    left: 200%;
    transform: translate(-50%, -50%);
    background-color: rgba(65, 97, 110, 0.8);
    color: white;
    font-size: small;
    padding: 2px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vh;
  }

  #deckMensagem{
    margin: 0;
    position: absolute;
    top: 133%;
    left: 48%;
    -ms-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
  }


.dialog-content {
    display: flex;
    justify-content: space-around; /* Alternativa: flex-direction: column; para alinhar verticalmente */
}

.dialog-button {
    padding: 10px 30px; /* Ajuste o preenchimento para estilizar os botões */
}

#trombeta{
    position:absolute;
    top:5%;
    left:-50%;
    width:100%;
}
#trombeta.hidden { display: none; }

#trombeta.anim { 
    animation-name: caminhada;
    animation-duration: 15s;
    visibility: hidden;
}

@keyframes caminhada {
    0%   {visibility: visible; left:-50%; top:5%;}
    100% {visibility: visible; left:100%; top:5%;}
  }

.botaoTrombeta{
    position: absolute;
    top: 40%;
    right: 11%;
    display: inline;
    text-align: right;
    width:10vh;
}

#mensagemTrombeta {
    position: absolute;
    top:45%;
    left: 90%;
    transform: translate(-50%, -50%);
    background-color: rgba(65, 97, 110, 0.8);
    color: white;
    font-size: small;
    padding: 2px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vh;
  }

  #funnyElephant{
    width: 40%;
  }

/* Estilo para o novo NAVBAR */

  .navbar-game {
    background-image: url(../../../public/images/fundobarra4.png);
    background-repeat: no-repeat; /* Faz a imagem se repetir */
    background-size: cover; /* Ajusta o tamanho da imagem, pode ser alterado conforme necessidade */
    height: 65px; /* Altura da barra de navegação */
    width: 100%; /* Garante que a barra de navegação use toda a largura disponível */
}

/* Estilo para a marca */
.navbar-brand {
    color: rgb(255, 34, 0); /* Cor do texto da marca */
    font-weight: bold; /* Negrito para destaque */
}

.navbar-brand:hover{
    color: rgb(255, 255, 255);
}


/* Contêiner que contém o nome do usuário e o ícone */
.d-flex.align-items-center {
    display: flex;
    align-items: center; /* Alinha os itens verticalmente ao centro */
    gap: 5px; /* Utiliza gap para controlar o espaçamento entre o nome e o ícone */
  }
  
  /* Nome do usuário */
  .nameuser {
    font-size: 16px; /* Tamanho da fonte */
    font-weight: bold; /* Negrito */
    margin: 0; /* Remover margens para evitar qualquer espaçamento extra */
  }
  
  /* Ícone */
  .icone {
    display: flex;
    align-items: center; /* Alinha o ícone verticalmente */
    justify-content: center; /* Garante que o ícone fique centralizado */
  }
  
  /* Tamanho do ícone */
  .icone img {
    width: 50px; /* Ajusta o tamanho do ícone */
    height: auto; /* Mantém a proporção do ícone */
  }
  