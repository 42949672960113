 
/* Estilo para a barra de navegação */
.navbar-store {
  background-image: url(../../../public/images/fundobarra4.png);
  background-repeat: no-repeat;
  background-size: cover; /* Ajusta o tamanho da imagem, pode ser alterado conforme necessidade */
  height: 65px; /* Altura da barra de navegação */
  width: 100%; /* Garante que a barra de navegação use toda a largura disponível */
}

/* Estilo para a marca */
.navbar-brand {
  color: rgb(255, 34, 0); /* Cor do texto da marca */
  font-weight: bold; /* Negrito para destaque */
}

.navbar-brand:hover{
  color: rgb(255, 255, 255);
}
.icone img {
  width: 50px; /* Define a largura do ícone */
  height: auto; /* Mantém a proporção */
  margin-top: 0%;
  margin-right: 30px;
}

/* Container da navbar para usar flexbox */
.d-flex {
  display: flex;
  justify-content: space-between; /* Espaça os itens entre eles */
  align-items: center; /* Alinha verticalmente no centro */
}


h1{
    display: flex;
    align-items: center; /* Centraliza verticalmente o texto em relação às imagens */
    justify-content: center; /* Centraliza horizontalmente o conteúdo (texto e imagens) */
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bold;
}

h1 img{
    width: 5vh;
}

#fundo{
    background-color: #201f1f99;
    padding: 3%;
}

h2, h3{
    color: #C9E8FC;
    text-align: center;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

h2{
  font-size: 1.5em;
}

h3{
  font-size: 1.2em;
}

.decks {
    display: flex;
    gap: 20px; /* Espaçamento entre os temas */
    margin-left: 20px;
  }
  
.decks div {
    text-align: center; /* Centraliza o texto em relação à imagem */
    margin-right: 25px; 
}
  
.decks img {
    height: 20vh;
    width: 15vh;
  }
  
.imagemMenor img{
    height: 13vh;
    width: 20vh;
}

.imagemMenorAcoes img{
  height: 13vh;
  width: auto;
}
.decks img:hover {
  transform: scale(1.1);
  cursor: pointer;
}
  
  .decks-container-full, .decks-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden; /* Oculta o conteúdo que excede a largura */
    position: relative;
    margin-top:20px;
  }
  .decks {
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth; /* Rola suavemente ao clicar nos botões */
    width: 100%;

  }
  
  .decks div {
    min-width: 150px; /* Largura mínima de cada item dentro da div "Decks" */
    margin-right: 10px;
    text-align: center;
  }
  
  .scroll-button {
        top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .scroll-left {
    left: -20px;
  }
  
  .scroll-right {
    right: -20px;
  }
  

.cardComponent{
  height: 30vh;
}

.cardComponent {
  perspective: 1000px; /* Permite o efeito 3D */
}

.cardComponent .card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  display: flex;
}

.cardComponent .card-front, .cardComponent .card-back {
  position: absolute;
  width: 100%;
  backface-visibility: hidden; 
}

.cardComponent .card-front {
  transform: rotateY(0deg);
}

.cardComponent .card-back {
  transform: rotateY(180deg);
}

.cardComponent.flip .card-inner {
  transform: rotateY(180deg);
}

.label{
    margin-right: 10px;
}