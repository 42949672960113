@import url('https://fonts.googleapis.com/css2?family=Matemasie&display=swap');

* {
    box-sizing: border-box;
}

.tela-jogo {
    background-image: url('../../../public/images/natureza4.png'); /* Caminho da imagem de fundo */
    background-size: cover; /* Preenche a tela */
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh; /* Garante que o background ocupe toda a altura da tela */
    display: flex;
    flex-direction: column;
}

/* Estilo para a barra de navegação */
.navbar-menu {
    background-image: url(../../../public/images/fundobarra4.png);
    background-repeat: no-repeat; /* Faz a imagem se repetir */
    background-size:cover; /* Ajusta o tamanho da imagem, pode ser alterado conforme necessidade */
    height: 65px; /* Altura da barra de navegação */
    width: 100%; /* Garante que a barra de navegação use toda a largura disponível */
}

.nameuser {
    margin-right: 100px !important; /* Define uma margem à direita do nome do usuário */
    color: #ffffff;
}

/* Estilo para a marca */
.navbar-brand {
    color: rgb(255, 34, 0); /* Cor do texto da marca */
    font-weight: bold; /* Negrito para destaque */
}

.navbar-brand:hover{
    color: rgb(255, 255, 255);
}
/*para ajustar o logo*/
.logo-container {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    width: 100%;
    height: 15vh;
    margin-top: 10px; /* Espaço entre a navbar e o logo */
}

.logonovo {
    height: 180px;
    max-width: 100%;
    animation: floatLogo 3s ease-in-out infinite; /* Animação de flutuação */
}

@keyframes floatLogo {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-15px); }
}

/* Ajuste responsivo para telas menores */
@media (max-width: 768px) {
    .logonovo {
        height: 150px; /* Ajusta a altura do logo em telas pequenas */
    }
}

.placa {
    background-image: url('../../../public/images/placa.png'); /* Insira o caminho da imagem da placa */
    background-size: contain; /* Ajusta o tamanho da imagem para caber no conteúdo */
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; /* Ajusta para a largura desejada */
    height: 380px; /* Ajuste conforme o tamanho da imagem */
    margin: 0 auto; /* Centraliza horizontalmente */
}


.icone img {
    width: 50px; /* Define a largura do ícone */
    height: auto; /* Mantém a proporção */
    margin-top: 0%;
    margin-right: 30px;
}

/* Container da navbar para usar flexbox */
.d-flex {
    display: flex;
    justify-content: space-between; /* Espaça os itens entre eles */
    align-items: center; /* Alinha verticalmente no centro */
}

h1 .menuprincipal{
    font-family: "Matemasie", sans-serif;
    color: white;
    font-size: 100px !important;
    text-align: center;
}
.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    background: none;
    flex-wrap: wrap; /* Adicionado para garantir que o layout se ajuste em telas menores */
}


.headline {
    flex: 1;
    margin: 20px;
}

.headline h2 {
    font-weight: 900;
    font-size: 30px;
    color: white;
    margin: 0;
}

.headline p {
    color: #ffffff;
    margin-top: 20px;
    text-align: left;
    font-size: 25px;
    line-height: 1.4;
}

.imgheadline {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.botao {
    display: inline-block;
    background: orangered;
    padding: 20px 35px;
    color: white;
    border-radius: 5px;
    margin: 10px auto;
    text-decoration: none;
    font-size: 20px;
    width: 200px;
    text-align: center;
    transition: background 0.3s;
}

.botao:hover {
    background: orange;
    cursor: pointer;
}

/* Ajustes para seções e cards */
section {
    display: inline-block;
    margin-top: 80px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 5%;
    text-align: center;
    width: 90%;
}

section h2 {
    font-size: 50px;
    margin: 0;
    font-family: "Matemasie", sans-serif;
    font-weight: bold;
    color: #180f4a;
}

.card {
    display: inline-block;
    width: 30%;
    padding: 20px;
    margin: 1%;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.card:hover {
    color: white;
}

@media (max-width: 768px) {
    .headline h2 {
        font-size: 30px;
    }

    .headline p {
        font-size: 25px;
    }

    .botao {
        font-size: 18px;
        padding: 15px 25px;
    }

    .card {
        width: 90%;
        margin: 5% auto;
    }
}

@keyframes moveUpDownInfinite {
    0% {
      transform: translateY(0); /* Começa na posição inicial */
    }
    50% {
      transform: translateY(-20px); /* Sobe 20px */
    }
    100% {
      transform: translateY(0); /* Volta para a posição inicial */
    }
  }
  
  .texto-movimento-continuo {
    animation: moveUpDownInfinite 5s ease-in-out infinite; /* Animação que repete infinitamente */
  }
  
  .texto-movimento {
    animation: moveUpDown 2s ease-in-out forwards; /* 2 segundos de animação */
  }